import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import './styles/quasar.scss'
import lang from 'quasar/lang/ro.js'
import interceptors from './modules/interceptors';
import '@quasar/extras/material-icons/material-icons.css'
import './assets/site.scss';
import dotenv from 'dotenv';

dotenv.config();

interceptors();
const quasarUserOptions={
    config: {},
    plugins: {
        Loading,AppFullscreen, Notify, Dialog
    },
    lang: lang

}

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).mount('#app')
